import React from 'react';
import {useSelector} from 'react-redux';
import {IState} from "../../types/state";

function Currency({value}: any) {
    const locale = useSelector((state: IState) => state.locale.code)
    const symbol = useSelector((state: IState) => state.rate.current.symbol)

    if (value) {
        value = value + ''
        if (value?.indexOf('.0000') != -1) {
            value = value.replace('.0000', ' ')
        } else if (value?.indexOf(',00') != -1) {
            value = value.replace(',00', ' ')
        } else if (value?.indexOf('.00') != -1) {
            value = value.replace('.00', ' ')
        }
    }

    return (
        <div className="currency_value">
            {locale === 'hy' ? (
                <>
                    <span>{`${value}`}</span>
                    <span>{symbol}</span>
                </>
            ) : (
                <>
                    {' '}
                    <span>{symbol}</span>
                    <span>{`${value}`}</span>

                </>
            )}
        </div>
    )
}

export default Currency
